import type { UseHeadInput } from '@vueuse/head';
import type { HeadAugmentations } from '@nuxt/schema';
import { useHead, useNuxtApp, useRoute, removeTrailingSlash } from '#imports';

const resolveUrl = (path: string, host: string, locale?: string) => {
  if (locale) {
    path = path.replace(/^\/([\w]{2})(-([\w]{2}))?\/?/g, `/${locale}/`);
  }

  return removeTrailingSlash(!host.startsWith('https://') ? `https://${host}${path}` : `${host}${path}`);
};

export const useUrlOrigin = () => {
  return 'https://' + (useNuxtApp().ssrContext?.event.node.req.headers.host || window.location.host);
};

export const useSeoKit = (
  input: UseHeadInput<HeadAugmentations> & { schema?: (context: { url: string }) => object | undefined } = {},
  path: string = useRoute().path,
) => {
  const route = useRoute();
  const { $i18n, ssrContext } = useNuxtApp();
  const host = ssrContext?.event.node.req.headers.host || window.location.host;

  if (!path.startsWith('/')) {
    path = '/' + path;
  }

  path = removeTrailingSlash(path);

  // @ts-expect-error untyped
  input.link = input.link || [];

  $i18n.locales.value.forEach((locale: any) => {
    // @ts-expect-error untyped
    input.link.push({
      rel: 'alternate',
      href: resolveUrl(path, host, locale.code),
      hreflang: locale.hreflang,
      title: locale.name,
    });
  });

  // @ts-expect-error untyped
  input.link.push({
    rel: 'alternate',
    href: resolveUrl(path, host, 'en'),
    hreflang: 'x-default',
  });

  // @ts-expect-error untyped
  input.link.push({
    rel: 'canonical',
    href: resolveUrl(path, host, $i18n.locale.value),
  });

  // @ts-expect-error untyped
  input.meta = [
    {
      name: 'description',
      content: $i18n.t('seo.metadesc'),
    },
    {
      property: 'og:title',
      content: $i18n.t('seo.metatitle'),
    },
    {
      property: 'og:description',
      content: $i18n.t('seo.metadesc'),
    },
    {
      property: 'og:image',
      content: 'https://cdn.airpaz.com/social-logo.png',
    },
    {
      property: 'og:url',
      content: resolveUrl(path, host, $i18n.locale.value),
    },
    {
      property: 'og:site_name',
      content: 'Airpaz.com',
    },
    {
      property: 'fb:app_id',
      content: '352869744803635',
    },
    {
      name: 'twitter:title',
      content: $i18n.t('seo.metatitle'),
    },
    {
      name: 'twitter:description',
      content: $i18n.t('seo.metadesc'),
    },
    {
      name: 'twitter:image',
      content: 'https://cdn.airpaz.com/logo.png',
    },
    {
      name: 'twitter:card',
      content: 'summary',
    },
    {
      name: 'twitter:url',
      content: resolveUrl(path, host, $i18n.locale.value),
    },
    { name: 'twitter:site', content: '@airpaz' },
    {
      name: 'twitter:creator',
      content: '@airpaz',
    },
    // @ts-expect-error
    ...(input.meta || []),
  ];
  // @ts-expect-error untyped
  input.meta.push({
    property: 'og:locale',
    content: $i18n.locale.value.replace(/-/g, '_'),
  });

  // @ts-expect-error untyped
  input.script = input.script || [];

  if (input?.schema) {
    const schema = input.schema({ url: route.path });
    // @ts-expect-error untyped
    input.script.push({
      innerHTML: JSON.stringify(schema),
      type: 'application/ld+json',
      body: true,
    });
  }

  useHead({
    title: $i18n.t('seo.metatitle'),
    ...input,
    htmlAttrs: {
      lang: $i18n.locale,
    },
  });
};
